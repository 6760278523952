import React from 'react'
import { graphql } from 'gatsby'
import { parseSeo } from '../../helpers/seo'
import Layout from '../../views/Layout'
import SEO from '../../views/Seo'
import { Heading } from '../../components/Heading'
import { Divider } from '../../components/Divider'
import { Text } from '../../components/Text'
import { Content } from '../../components/Content'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import { PageContent } from '../../components/PageContent'
import { IndentWrapper } from '../../blocks/IndentWrapper'
import { Grid } from '../../components/Grid'
import { SPACING, COLORS } from '../../helpers/constants'
import { Container } from '../../components/Container'
import { TextLink } from '../../components/TextLink'
import { getUrl } from '../../../node/functions'

export default ({ data, pageContext }) => {
  const page = data.wordpress.page
  const { words } = pageContext.data
  const {
    seo,
    acfPageTemplateWordListing: { title, text },
  } = page

  const parsedWords = words.nodes
  if (parsedWords.length)
    parsedWords.sort((a, b) => a.title.localeCompare(b.title))

  return (
    <Layout>
      <SEO {...parseSeo(seo)} />
      <Breadcrumbs breadcrumbs={seo.breadcrumbs} />
      <PageContent>
        <Content>
          <Container>
            <IndentWrapper col={3}>
              <Content>
                <Heading type={'h1'} size={1} color={COLORS.PRIMARY_RED}>
                  {title}
                </Heading>
                <Text columns={2}>{text}</Text>
              </Content>
            </IndentWrapper>
          </Container>
          <Divider
            size={{
              desktop: SPACING.LARGE,
              tablet: SPACING.MEDIUM,
              mobile: SPACING.SMALL,
            }}
          />
          <Container>
            <Grid col={4} rowGap={'small'}>
              {parsedWords.map((word, index) => {
                const { id, uri, title } = word
                return (
                  <TextLink key={id} to={getUrl(uri)} text={title} inglossary />
                )
              })}
            </Grid>
          </Container>
        </Content>
      </PageContent>
    </Layout>
  )
}

export const query = graphql`
  query($id: ID!) {
    wordpress {
      page(id: $id) {
        ...Single_Page
        acfPageTemplateWordListing {
          title
          text
        }
      }
    }
  }
`
